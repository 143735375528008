enum Replacements {
    OATI = 'ОАТИ',
    EISOATI = 'ЕИС ОАТИ',
    GLAVCONTROL = 'главконтрол',
    KNMPM = 'КНМПМ',
    MAF = 'МАФ',
    FIO = 'ФИО инспектора',
    WITH_RELEASE = 'со сдачей',
    INNOPOLIS = 'Иннополис',
    DATA_SCIENTIST = 'датасайнтистов',
    CONCENTRATE = 'сконцентрировались',
    // ANN = 'Анн',
    WITH_TATOIL = 'с Татнефтью',
    AUTO_UPDATE = 'автообновляемые',
    REMOVED = 'убран',
    BY_USUAL = 'по штатной',
    DIT = 'ДИТ',
    TVER = 'Тверью',
    CANNOT_DRIVE = 'никак доехать',
    YES_DIM = 'да Дим',
    AI_KIND = 'ИИшные',
    AI_AGENTS = 'ИИ-агента',
    GIGACHAT = 'гигачат',
    A_100 = 'А 100',
    CALL_IN_12 = 'созвон в двенадцать',
    AI_AGENT = 'ИИ-агент',
    DANIIL = 'Даниил',
    BORDERS = 'рамках',
    IQ = 'интеллекта',
    ROOFS = 'кровель',
    NDA = 'NDA',
    INTEGRATING = 'интегрятся',
    TRELLO = 'Trello',
    EFIMOV = 'Ефимова',
    MY_SHOW = 'разделим да мой показ',
    BAGREEVA = 'Багреевой',
    RESPONSIBLE_SEES = ' ответственный видит ',
    TOP_LEVEL = 'вышестоящую',
    TO_TO_SUBTASK = 'то-то подзадачу',
    SUBTASKS = 'подзадачи',
    EXECUTIONER = 'исполнителей',
    EDO = 'ЭДО',
    JIRA = 'Jira',
    TO_REPORT = 'отчету',
    NO_DEPS_FROM_DIT = 'поэтому мы тут от ДИТа никак не зависим',
    DIT_GAVE_RESOURCES = 'но сейчас ДИТ нам дал достаточное количество',
    HOW_IT_FALL = 'А проваливается как туда',
    // AS_BALASHOV = 'Также да Балашова',
    ON_SHULGINA = 'на Шульгиной',
    SUBSYSTEMS = 'подсистему',
    UNREMOVABLE = 'неустранимо',
    CHECK_LISTS = 'чек-листам',
    MANAGEMENT_LOGGED_IN = 'руководители залогинины',
    OF_DND = 'драк-энд-дропа',
    // MARIA_EKATERINA = 'Мария Екатерина',
    DASHBORD_WILL_MADE = 'такой дашборд и сделаем',
    // COWORKERS_VISION = 'видения коллег',
    SEE_BOLD_POSITION = 'увидеть жирным позицию',
    WILL_DISPLAYED_IN_SYSTEM = 'будет отражен в системе',
    WILL_REMOVE_THERE_HALF = 'И уберут там половину',
    // YES_ANN = 'да, Анн',
    // THEN_YES_DIM = 'тогда да Дим',
    // DANIIL_OF_COURSE = 'Даниил, конечно',
}

const replacementsArray = [
    // {
    //     re: /(Глеб, конечно)/g,
    //     replacement: Replacements.DANIIL_OF_COURSE
    // },
    // {
    //     re: /(тогда дадим)/g,
    //     replacement: Replacements.THEN_YES_DIM
    // },
    // {
    //     re: /(да, Антон)/g,
    //     replacement: Replacements.YES_ANN
    // },
    {
        re: /(будет воображен в системе)/g,
        replacement: Replacements.WILL_DISPLAYED_IN_SYSTEM
    },
    {
        re: /(И умерут мы там половина)/g,
        replacement: Replacements.WILL_REMOVE_THERE_HALF
    },
    // {
    //     re: /(увидеть жирную позицию)/g,
    //     replacement: Replacements.SEE_BOLD_POSITION
    // },
    // {
    //     re: /(введения коллег)/g,
    //     replacement: Replacements.COWORKERS_VISION
    // },
    {
        re: /(такой договор и сделаем)/g,
        replacement: Replacements.DASHBORD_WILL_MADE
    },
    // {
    //     re: /(Марина Екатерина)/g,
    //     replacement: Replacements.MARIA_EKATERINA
    // },
    {
        re: /(дракон-дропа)/g,
        replacement: Replacements.OF_DND
    },
    {
        re: /(родители залогили)/g,
        replacement: Replacements.MANAGEMENT_LOGGED_IN
    },
    {
        re: /(чекистам)/g,
        replacement: Replacements.CHECK_LISTS
    },
    {
        re: /(невыстранимо)/g,
        replacement: Replacements.UNREMOVABLE
    },
    {
        re: /(под систему)/g,
        replacement: Replacements.SUBSYSTEMS
    },
    {
        re: /(нашу геной)/g,
        replacement: Replacements.ON_SHULGINA
    },
    // {
    //     re: /(Также дам Балашова)/g,
    //     replacement: Replacements.AS_BALASHOV
    // },
    {
        re: /(А проваливается как-то, да)/g,
        replacement: Replacements.HOW_IT_FALL
    },
    {
        re: /(но сейчас сидит нам дал достаточное количество ресурсов)/g,
        replacement: Replacements.DIT_GAVE_RESOURCES
    },
    {
        re: /(поэтому мы тут от деталей никак не зависим)/g,
        replacement: Replacements.NO_DEPS_FROM_DIT
    },
    {
        re: /(качету)/g,
        replacement: Replacements.TO_REPORT
    },
    {
        re: /(под задачи)/g,
        replacement: Replacements.SUBTASKS
    },
    {
        re: /(жира)/g,
        replacement: Replacements.JIRA
    },
    {
        re: /(Adobe)/g,
        replacement: Replacements.EDO
    },
    {
        re: /(исполнительных)/g,
        replacement: Replacements.EXECUTIONER
    },
    {
        re: /(кто-то под задачу)/g,
        replacement: Replacements.TO_TO_SUBTASK
    },
    {
        re: /(высшедающую)/g,
        replacement: Replacements.TOP_LEVEL
    },
    {
        re: /( ответственно видит )/g,
        replacement: Replacements.RESPONSIBLE_SEES
    },
    {
        re: /(Багрея)/g,
        replacement: Replacements.BAGREEVA
    },
    {
        re: /(разделим домой показ)/g,
        replacement: Replacements.MY_SHOW
    },
    {
        re: /(и FIMO|эфимова)/g,
        replacement: Replacements.EFIMOV
    },
    {
        re: /(Trevo)/g,
        replacement: Replacements.TRELLO
    },
    {
        re: /(идеи)/g,
        replacement: Replacements.NDA
    },
    {
        re: /(ESU-AT|ЕСУАТИ|ESOT)/g,
        replacement: Replacements.EISOATI
    },
    {
        re: /( АТИ| УАТИ)/g,
        replacement: ` ${Replacements.OATI}`
    },
    {
        re: /(УАТИ)/g,
        replacement: Replacements.OATI
    },
    {
        re: /(к НМПМ|KNMPM)/g,
        replacement: Replacements.KNMPM
    },
    {
        re: /( МПМ)/g,
        replacement: ` ${Replacements.KNMPM}`
    },
    {
        re: /( мав)/g,
        replacement: ` ${Replacements.MAF}`
    },
    {
        re: /(глав контрол)/g,
        replacement: Replacements.GLAVCONTROL
    },
    {
        re: /( а два контроля)/g,
        replacement: ` ${Replacements.GLAVCONTROL}ь`
    },
    {
        re: /(FIO-инспектор[уа]?)/g,
        replacement: `${Replacements.FIO}`
    },
    {
        re: /(создачи)/g,
        replacement: `${Replacements.WITH_RELEASE}`
    },
    {
        re: /(Анаполис)/g,
        replacement: `${Replacements.INNOPOLIS}`
    },
    {
        re: /(фундаменталистов)/g,
        replacement: `${Replacements.DATA_SCIENTIST}`
    },
    {
        re: /(сконцентрирулись)/g,
        replacement: `${Replacements.CONCENTRATE}`
    },
    // {
    //     re: /(Антон)/g,
    //     replacement: `${Replacements.ANN}`
    // },
    {
        re: /(«Стать нефтью»)/g,
        replacement: `${Replacements.WITH_TATOIL}`
    },
    {
        re: /(автородавляемые)/g,
        replacement: `${Replacements.AUTO_UPDATE}`
    },
    {
        re: /(обран)/g,
        replacement: `${Replacements.REMOVED}`
    },
    {
        re: /(поштатной)/g,
        replacement: `${Replacements.BY_USUAL}`
    },
    {
        re: /(ДИК|ДИД|DIT)/g,
        replacement: `${Replacements.DIT}`
    },
    {
        re: /(Тверио)/g,
        replacement: `${Replacements.TVER}`
    },
    {
        re: /(никогда ехать)/g,
        replacement: `${Replacements.CANNOT_DRIVE}`
    },
    {
        re: /( дадим)/g,
        replacement: ` ${Replacements.YES_DIM}`
    },
    {
        re: /(яичные)/g,
        replacement: ` ${Replacements.AI_KIND}`
    },
    {
        re: /(EA-агента)/g,
        replacement: ` ${Replacements.AI_AGENTS}`
    },
    {
        re: /(NegaChat)/g,
        replacement: ` ${Replacements.GIGACHAT}`
    },
    {
        re: /(АС-то)/g,
        replacement: ` ${Replacements.A_100}`
    },
    {
        re: /(сезон-двенадцать)/g,
        replacement: ` ${Replacements.CALL_IN_12}`
    },
    {
        re: /(E-агент|e-agent)/g,
        replacement: ` ${Replacements.AI_AGENT}`
    },
    {
        re: /(Глеб)/g,
        replacement: ` ${Replacements.DANIIL}`
    },
    {
        re: /(рампах)/g,
        replacement: ` ${Replacements.BORDERS}`
    },
    {
        re: /(интерьера)/g,
        replacement: ` ${Replacements.IQ}`
    },
    {
        re: /(крови)/g,
        replacement: ` ${Replacements.ROOFS}`
    },
]

export const substringReplace = (content: string) => {
    let result = content;

    replacementsArray.forEach((item) => {
        result = result.replaceAll(item.re, item.replacement)
    })

    return result;
};