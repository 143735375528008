import React, { Fragment, ReactNode } from "react";
import clsx from "clsx";
import parse from 'html-react-parser';

import { Tabs } from './Tabs/Tabs';
import { TabsWrapper } from './Tabs/TabsWrapper';
import { Content } from './Tabs/Content';
import { Button } from '../Uikit/Button';
import { ProcessingLoader } from '../Uikit/ProcessingLoader';
import { substringReplace } from '../helpers/substringReplace';

export interface FileSizeByType {
    [key: string]: number;
}

interface RecognitionTabsProps {
    className?: string;
    transcriptionContent?: string | null;
    protocolContent?: string | null;
    transcriptionToWordHandler: (content: string, name: string) => void;
    protocolToWordHandler: (content: string, name: string) => void;
}

interface RecognitionTabsBlockProps {
    title?: string
    content: string | null;
    handleSubmit: () => void;
    children: ReactNode
}

const RecognitionTabsBlock = ({
    title = '',
    content = '',
    handleSubmit,
    children,
}: RecognitionTabsBlockProps) => {
    return (
        <TabsWrapper>
            <TabsWrapper.Tabs childrenWrapperClassName="relative w-full">
                <Tabs.Tab 
                    title={title}
                    selectedClassName="cursor-default" 
                />
                <Button
                    onClick={handleSubmit}
                    disabled={!content}
                    className="absolute right-0 top-1/2 -translate-y-1/2 !m-0"
                >
                    Выгрузить в Word
                </Button>
            </TabsWrapper.Tabs>
            <TabsWrapper.Content>
                <Content.Body>
                    <div className={clsx('py-2.5 px-4.5 min-h-100 border border-gray-blue rounded-md flex flex-col', 
                        !content && "justify-center"
                    )}>
                        {children}
                    </div>
                </Content.Body>
            </TabsWrapper.Content>
        </TabsWrapper>
    )
}

interface IRenderContentProps {
    content: string, 
    separator?: string, 
    className?: string,
    handleSpeakers?: boolean;
};

const re = /(SPEAKER_)\w+/g;

const RenderTranscriptionContent = ({ content, separator = '\n', className = '' }: IRenderContentProps) => {
    const contentItems: string[] = [];

    substringReplace(content)
        .split(separator)
        .filter((item: string) => item)
        .forEach((item: string) => {
            let textItem = item;

            const match = textItem.match(re);

            if (match) {
                textItem = `<strong>${match[0]}</strong>${textItem.replace(re, '')}`;
            } else {
                contentItems[contentItems.length - 1] = `${contentItems.at(-1)} ${textItem}`;
                return;
            }

            contentItems.push(textItem);
        })

    return (
        <>
            {
                contentItems
                    .map((item, index) => {
                        const textItem = item.trim();

                        return <Fragment key={`${item}__${index}`}>
                            {
                                index !== 0 && textItem.includes('SPEAKER') && (
                                    <br />
                                )
                            }
                            <p className={className}>{parse(textItem)}</p>
                        </Fragment>
                    })
            }
        </>
    )
} 

const RenderProtocolContent = ({ content, separator = '\n', className = '' }: IRenderContentProps) => {
    const contentItems: string[] = [];

    substringReplace(content)
        .replace(/(Тема встречи: )/g, '') // Иногда ИИ дублирует этот подзаголовок
        .split(separator)
        .filter((item: string) => item)
        .forEach((item: string) => {
            // приходится делать такую замену по причине странных символов в составе строки - из-за них слово "Список" в .docx потом подчёркивается красным как содержащий ошибку
            const textItem = item.replaceAll('"', '').replaceAll(/(Тема встречи|Список участников|Cписок участников|Резюме встречи)/gi, '<strong>$&</strong>');

            contentItems.push(textItem);
        })

    return (
        <>
            {
                contentItems
                    .map((item, index) => {
                        const textItem = item.trim();

                        return <Fragment key={`${item}__${index}`}>
                            <p className={className}>{parse(textItem)}</p>
                        </Fragment>
                    })
            }
        </>
    )
} 

export const RecognitionTabs = (
        {
            className = '',
            transcriptionContent = null,
            protocolContent = null,
            transcriptionToWordHandler,
            protocolToWordHandler,
        }: RecognitionTabsProps,
    ) => {

    return (
        <div className={`w-full flex py-4 rounded-md h-full gap-5 ${className}`}>
            <div className="grow w-full">
                <RecognitionTabsBlock 
                    title="Результаты транскрибирования" 
                    content={transcriptionContent}
                    handleSubmit={() => {
                        transcriptionToWordHandler(String(transcriptionContent), "Результаты транскрибирования");
                    }}
                >
                    <>
                        {transcriptionContent 
                            ? (
                                <RenderTranscriptionContent 
                                    content={transcriptionContent} 
                                />
                            ) 
                            : (
                                <ProcessingLoader
                                    className="border-0" 
                                    title="Результаты формируются"
                                />
                            )
                        }
                    </>
                </RecognitionTabsBlock>
            </div>
            <div className="grow w-full">
                <RecognitionTabsBlock 
                    title="Протокол" 
                    content={protocolContent}
                    handleSubmit={() => {
                        protocolToWordHandler(String(protocolContent), "Протокол");
                    }}
                >
                    <>
                        {protocolContent 
                            ? (
                                <RenderProtocolContent content={protocolContent} separator='\n\n' className="whitespace-pre-wrap" />
                            ) 
                            : (
                                <ProcessingLoader
                                    className="border-0" 
                                    title="Протокол формируется"
                                />
                            )
                        }
                    </>
                </RecognitionTabsBlock>
            </div>
        </div>
    );
};
